import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subtotal"
export default class extends Controller {

    static targets = [ 'checkbox', 'description', 'amount', 'quantity', 'subtotalsum', "subtotalshipping" ]

    static values = {
        page: { type: String, default: "none"}
    };

    connect() {
        console.log("Connected to subtotal controller!")
        this.start();

        const trigger = new CustomEvent('sum-again');
        window.dispatchEvent(trigger);
    }

    start() {
        if (this.checkboxTarget.checked) {
            console.log("Hurray, Checked")
            console.log("Amount: " + this.amountTarget.textContent)
            console.log(this.quantityTarget.value)
            console.log("Quantity: " + this.quantityTarget.value)

            let amountFloat = parseFloat(this.amountTarget.textContent)
            console.log(amountFloat)
            //console.log("Amount Float: " + amountFloat.toString())
            let total = amountFloat * this.quantityTarget.value
            // console.log("TOTAL: " + total.toString() )
            this.subtotalsumTarget.textContent = total.toFixed(2);
            console.log("NOT YET CHECKING PAGE VALUE SECTION")
            console.log("PAGE VALUE " + this.pageValue)
            if (this.pageValue == "saorder") {
                console.log("ENTERING PAGE VALUE SECTION")
                if (this.hasSubtotalshippingTarget) {
                    this.subtotalshippingTarget.textContent = total.toFixed(2)
                    //console.log("SHIPPING IS " + total.toFixed(2))
                }
            }
        } else {
            this.subtotalsumTarget.textContent = "0";
            if (this.hasSubtotalshippingTarget) {
                this.subtotalshippingTarget.textContent = "0";
            }
        }
    }
}
