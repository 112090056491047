import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sumpayment"
export default class extends Controller {
    static targets = [ 
        "subtotal", 
        "itemstotal", 
        "amount", 
        "quantity", 
        "sumpaymentshipping",
        "shippingsubtotal",
        "totalplusshipping",
        "ccTotalDue",
        "card",
        "check",
        "hideable",
        "resum"
    ]

    static values = {
        page: { type: String, default: "none"}
    };

    connect() {
        console.log("Connected to sumpayment controller!")
        this.sum();
    }



    sum() {
        let total = 0.00
        let shippingTotal = 0.00 // total to use to calculate shipping
        console.log("FIRST SHIPPING TOTAL:" + shippingTotal)
        let shippingCost = 0.00 // actual shipping cost

        this.subtotalTargets.forEach((element) => {
            if (element.textContent != "" ) {
                total = total + parseFloat(element.textContent);
            }
        })

        // SA ORDER SHIPPING FEE CALCULATION

        if (this.pageValue == "saorder") {
            this.sumpaymentshippingTargets.forEach((element) => {
                if (element.textContent != "") {
                    shippingTotal = shippingTotal + parseFloat(element.textContent);
                    console.log("SHIPPING TOTAL: " + shippingTotal)
                } 
            })
            this.shippingsubtotalTarget.textContent = 0.00.toFixed(2)
            if (shippingTotal > 0 && shippingTotal <= 10.00)
                {this.shippingsubtotalTarget.textContent = 2.50.toFixed(2)
                shippingCost = 2.50}
            else if (shippingTotal > 10.00 && shippingTotal <= 20.00)
                {this.shippingsubtotalTarget.textContent = 3.50.toFixed(2)
                shippingCost = 3.50}
            else if (shippingTotal > 20.00 && shippingTotal <= 50.00)
                {this.shippingsubtotalTarget.textContent = 5.00.toFixed(2)
                shippingCost = 5.00}
            else if (shippingTotal > 50.00 && shippingTotal <= 100.00)
                {this.shippingsubtotalTarget.textContent = 6.00.toFixed(2)
                shippingCost = 6.00}
            else if (shippingTotal > 100.00)
                {this.shippingsubtotalTarget.textContent = 12.00.toFixed(2)
                shippingCost = 12.00}
        }


        this.itemstotalTarget.textContent = total.toFixed(2);
        //console.log("SHIPPING COST " + shippingCost)
        let total_plus_shipping = 0.00
        total_plus_shipping = total + shippingCost

        console.log("TOTAL PLUS SHIPPING 1 TYPE: " + typeof total_plus_shipping)
        //console.log("TOTAL PLUS SHIPPING " + total_plus_shipping)
        
        this.totalplusshippingTarget.value = total_plus_shipping.toFixed(2)
        console.log("TOTAL PLUS SHIPPING 2 TYPE: " + typeof total_plus_shipping)
        console.log("PERCENTAGE TYPE: " + typeof 0.035)
        let cc_charge = 0.00
        cc_charge = (total_plus_shipping * 0.035).toFixed(2)
        //console.log("CC CHARGE " + cc_charge.toFixed(2))

        console.log("CC_CHARGE: " + typeof cc_charge)
        this.ccTotalDueTarget.value = (total_plus_shipping + Number(cc_charge)).toFixed(2)

        // used in pay.html.erb ??
        if (this.hasAmountTarget) {
            console.log("In the IF statement")
            this.amountTarget.value = grandTotal.toFixed(2);
            console.log("VALUE: " + this.amountTarget.value)
        }
    }

    hidecc() {
        this.hideableTarget.hidden = true;
    }

    showcc() {
        this.hideableTarget.hidden = false;
    }

}
